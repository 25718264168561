<template>
  <PeakHourStatisticChart  style="height:100%;"/>
</template>

<script>
import PeakHourStatisticChart from '@/views/charts/PeakHourStatisticChart'

export default {
  name: 'TrendPeakHourStatistic',
  components: {
    PeakHourStatisticChart
  }
}
</script>

<style>

</style>
