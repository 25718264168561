<template>
    <div>
        <CCard>
            <CCardBody>
                <CRow>
                    <CCol  sm="3">
                        <CInput
                            type="date"
                            label="Date From"
                            placeholder=""
                            description="Masukkan tanggal awal pencarian."
                            v-model="start_date"
                            @change="dateChange()"
                            required
                        />
                    </CCol>
                    <CCol v-if="selected != 'Day'" sm="3">
                        <CInput
                            type="date"
                            label="Date To"
                            placeholder=""
                            description="Masukkan tanggal akhir pencarian."
                            v-model="end_date"
                            @change="dateChange()"
                            required
                        />
                    </CCol>
                    <CCol v-if="role == 1" sm="3">
                        <CSelect 
                            :value.sync="keyword"
                            name="keyword"
                            label="Filter By"
                            :options="keywords"
                            @change="siteChange($event)"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <CCard>
            <CCardBody>
                <CRow>
                    <CCol sm="5">
                        <h4 id="traffic" class="card-title mb-0">Peak Hour Statistic</h4>
                        <div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
                    </CCol>
                    <CCol sm="7" class="d-none d-md-block">
                        <CButtonGroup class="float-right mr-3">
                            <CButton
                                color="outline-secondary"
                                
                                v-for="(value, key) in ['Day', 'Month']"
                                :key="key"
                                class="mx-0"
                                :pressed="value === selected ? true : false"
                                @click="selected = value"
                                v-on:click="FilterDate(selected)"
                            >
                                {{value}}
                            </CButton>
                        </CButtonGroup>
                    </CCol>
                </CRow>
                <CChartBar  style="height:100%; margin-top:40px;"
                    :datasets="defaultDatasets"
                    :labels="label"
                />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import { CChartBar } from '@coreui/vue-chartjs'
    import http from '@/utils/http-common';
    import moment from 'moment';

    export default {
        name: 'CChartBarExample',
        components: { CChartBar },
        data () {
            return {
                user_site: JSON.parse(localStorage.getItem('user'))['user']['site_id'],
			    role: +localStorage.getItem('role'),
                site_id: 0,
                start_date: '',
      		    end_date: '',
                data: [],
                selected: 'Month',
                label: [],
                keyword: 'DC TB Simatupang',
			    keywords: [
			    	'DC TB Simatupang', 'DC Taman Tekno', 'DC Jatiluhur'
			    ],
            }
        },
        methods: {
            date(value, format) {
                return moment(String(value)).locale('Id').format(format);
            },
            date2Str(x, y) {
                const z = {
                        M: x.getMonth() + 1,
                        d: x.getDate(),
                        h: x.getHours(),
                        m: x.getMinutes(),
                        s: x.getSeconds()
                    };
                    y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
                    return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
                });

                return y.replace(/(y+)/g, function(v) {
                    return x.getFullYear().toString().slice(-v.length)
                });
            },
            dateChange(){
                // console.log(this.end_date)
                if(this.selected == 'Day'){
                    this.end_date = this.start_date
                    // console.log(this.start_date)
                    // console.log(this.end_date)
                    // console.log(this.selected)
                }
               
                this.data = [];
                this.label = [];
                this.getPeak();
            },
            siteChange(event) {
                this.keyword = event.target.value;
                this.data = [];
                this.label = [];
                this.getPeak();
            },
            FilterDate(selected){
                // console.log(selected)
                let self = this;
                self.selected = selected;
                self.data = [];
                self.label = [];
                let start
                if(self.selected == 'Month'){
                    start = new Date()
                    let month = start.getMonth()
                    let day = start.getDate()
                    let year = start.getFullYear()
                    if(month == 0){
                        month = 12
                        year = start.getFullYear() - 1
                    }
                    if(day <= 9 ){
                        day = '0'+day
                    }
                    self.start_date = year+'-'+month+'-'+day
                }else if(self.selected == 'Year'){
                    start = new Date()
                    let month = start.getMonth() + 1
                    let year = start.getFullYear() - 1
                    let day = start.getDate()
                     if(day <= 9 ){
                        day = '0'+day
                    }
                    self.start_date = year+'-'+month+'-'+day
                }else{
                    self.start_date = self.date2Str(new Date(), 'yyyy-MM-dd');
                }
                self.end_date = self.date2Str(new Date(), 'yyyy-MM-dd');
                // console.log(self.selected);
                self.getPeak();
            },
            getSiteId() {
                this.site_id = this.role <= 2 ? 1 : +this.user_site;
            },
            baseDate(){
                let start
                start = new Date()
                let month = start.getMonth()
                let day = start.getDate()
                let year = start.getFullYear()
                if(month == 0){
                    month = 12
                    year = start.getFullYear()-1
                }
                if(day <= 9 ){
                    day = '0'+day
                }
                this.start_date = year+'-'+month+'-'+day
                this.end_date = this.date2Str(new Date(), 'yyyy-MM-dd');
            },
            getPeak() {
                let self = this; 
                let filter

                if(self.selected == 'Month'){
                    filter = 'monthly'
                }else{
                    filter = 'daily'
                } 

                let site

                if(self.site_id == 0){
                    if(self.keyword == 'DC TB Simatupang'){
                        site = 1
                    }else if(self.keyword == 'DC Taman Tekno'){
                        site = 2
                    }else if(self.keyword == 'DC Jatiluhur'){
                        site = 3
                    }
                }else{
                     if(self.site_id == 1){
                        self.keyword == 'DC TB Simatupang'
                    }else if(self.site_id == 2){
                        self.keyword == 'DC Taman Tekno'
                    }else if(self.site_id == 3){
                        self.keyword == 'DC Jatiluhur'
                    }
                    site = self.site_id
                }

                
                var rangeDate = '/cardusage/peak-hours-statistic?filter='+filter+'&from='+self.start_date+'&to='+self.end_date+'&site='+site
                // console.log(rangeDate)
                return http.get(rangeDate)
                    .then(function (response) {
                        // console.log(response.data)
                        if(response.data.type != 'empty'){
                                response.data.data.statistic.forEach(element => {
                                self.data.push(element.count);
                                self.label.push(element.label);
                            });
                        }
                        // console.log(self.label)
                    }).catch(function (error) {
                        console.log(error);
                    });
            },
        },
        computed: {
            defaultDatasets () {
            return [
                {
                    label: 'Peak Hour Statistic',
                    backgroundColor: '#f87979',
                    data: this.data
                }
            ]
            }
        },
        mounted () {
            this.baseDate();
            this.getPeak();
        },
    }
</script>

